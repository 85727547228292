

































































































































import Vue from 'vue'
import { mapActions } from 'vuex'
import xlsx from 'xlsx'
import CreditLine from '../../../../../../store/models/CreditLine'

export default Vue.extend({
  components: {},

  data() {
    return {
      loading: false,
      hovered: false,
      file: null,
      creditLine: '',
      headers: [],
      items: [],
      totalColumn: 22
    }
  },

  computed: {
    creditLines() {
      return CreditLine.getCompletedForCurrentOrganization()
    },

    isValid(this: any) {
      return this.creditLine
    },

    tableColumns(this: any) {
      return this.headers.map((label: string, index: number) => ({
        label,
        field: index,
        sortable: false
      }))
    },

    tableItems(this: any) {
      return this.items.map((row: any) => Object.assign({}, row))
    }
  },

  created() {
    CreditLine.api().fetchAll()
  },

  methods: {
    ...mapActions('entities/dispatching_files', ['updateNewDispatchingFile']),

    reset() {
      this.file = null
      this.headers = []
      this.items = []
    },

    onFileChanged(this: any, event: any) {
      this.$emit('invalidFile', '')
      if (event.target.files.length > 0) {
        this.file = event.target.files[0]

        const fileReader = new FileReader()

        this.loading = true

        fileReader.addEventListener('load', (event: any) => {
          const data = event.target.result
          const workbook = xlsx.read(data, {
            type: 'binary',
            cellDates: true
          })
          /* Get first worksheet */
          const wsname = workbook.SheetNames[0]
          const ws = workbook.Sheets[wsname]
          /* Convert array of arrays */
          const rows = xlsx.utils
            .sheet_to_json(ws, { header: 1 })
            .filter((row: any) => row.length > 0)

          this.headers = rows.shift()
          let preItems = []
          preItems = rows.map((row: any) => {
            ;(row as any).forEach((cell: any, index: number) => {
              if (cell instanceof Date) {
                cell.setHours(cell.getHours() + 1)
              }
              row[index] = cell
            })

            return row
          })

          if (preItems.length == 0)
            this.$emit('invalidFile', 'Le fichier ne contient aucune donnée')
          if (
            preItems.length != 0 &&
            Object.keys(preItems[0]).length < this.totalColumn
          ) {
            this.$emit(
              'invalidFile',
              'Le fichier entré ne respecte pas le format requis'
            )
          } else this.items = preItems
          this.loading = false
        })

        fileReader.readAsBinaryString(this.file)
      }

      event.target.value = ''
    },

    onNextStep(this: any) {
      this.updateNewDispatchingFile({
        file: this.file,
        headers: this.headers,
        items: this.items,
        creditLineId: this.creditLine
      })
      this.$parent.nextStep()
    }
  }
})
