


























































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  data() {
    return {
      fields: [
        { label: 'Agence du SFD', mapping: 0, field: 'agency' },
        { label: 'Nom complet', mapping: 1, field: 'name' },
        { label: 'Sexe', mapping: 2, field: 'gender' },

        { label: 'NPI', mapping: 3, field: 'npi' },
        {
          label: 'NOM DU BENEFICIAIRE RAVIP',
          mapping: 4,
          field: 'ravipLastname'
        },
        {
          label: 'PRENOMS DU BENEFICIAIRE RAVIP',
          mapping: 5,
          field: 'ravipFirstname'
        },

        { label: 'Département', mapping: 6, field: 'state' },
        { label: 'Commune', mapping: 7, field: 'commune' },
        { label: 'Arrondissement', mapping: 8, field: 'district' },
        { label: 'Village', mapping: 9, field: 'town' },
        { label: 'Numéro mobile', mapping: 10, field: 'phone' },
        { label: 'Groupe de solidarité', mapping: 11, field: 'group' },
        { label: 'Numéro du dossier', mapping: 12, field: 'reference' },
        { label: 'Poste occupé', mapping: 13, field: 'position' },
        { label: "Secteur d'activité", mapping: 14, field: 'activity' },
        { label: 'Montant du crédit', mapping: 15, field: 'amount' },
        {
          label: 'Montant à rembourser en nominal',
          mapping: 16,
          field: 'refundAmount',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Montant à rembourser en interêt',
          mapping: 17,
          field: 'interestAmount',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Montant total à rembourser',
          mapping: 18,
          field: 'totalRefund',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: "Montant de l'échéance",
          mapping: 19,
          field: 'deadlineAmount',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Durée du prêt',
          mapping: 20,
          field: 'loanDuration',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Différé',
          mapping: 21,
          field: 'deferred',
          format: (x: any) => parseInt(x, 10)
        }
      ]
    }
  },

  computed: {
    ...mapGetters('entities/dispatching_files', ['newDispatchingFile']),

    mapping(this: any) {
      return this.fields.reduce(
        (mapping: Record<string, number>, field: any) => {
          mapping[field.field] = field.mapping
          return mapping
        },
        {}
      )
    },

    items(this: any) {
      return Object.assign({}, this.newDispatchingFile.items)
    },

    columns() {
      return this.newDispatchingFile.headers.map(
        (header: string, i: number) => ({
          value: i,
          text: header
        })
      )
    },

    firstItem(this: any) {
      return this.items[0]
    }
  },

  methods: {
    ...mapActions('entities/dispatching_files', [
      'updateNewDispatchingFileMapping'
    ]),

    setMapping(this: any, fieldIndex: number, headerIndex: number) {
      this.fields[fieldIndex].mapping = headerIndex
    },

    onConfirm(this: any) {
      this.updateNewDispatchingFileMapping(this.mapping)
      this.$parent.nextStep()
    }
  }
})
