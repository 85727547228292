





































import Vue, { Component } from 'vue'

import UploadForm from './upload.vue'
import FieldMapping from './mapping.vue'
import SubmitFile from './submit.vue'
import { mapGetters } from 'vuex'

interface Step {
  title: string
  component: Component
}

interface ComponentOpts extends Vue {
  steps: Step[]
}

export default Vue.extend({
  data() {
    return {
      activeStep: 0,
      links: [
        {
          link: '/api/static/template-fichier-dispatching.xlsx',
          text: 'Télécharger le modèle de fichier de dispatching'
        },
        {
          link:
            '/api/static/liste-des-villages-quartiers-de-ville-du-Bénin.pdf',
          text: 'Télécharger le fichier du découpage territorial du Bénin'
        },
        {
          link: '/api/static/secteurs-d_activites.pdf',
          text: 'Télécharger le fichier listant les secteurs d’activité'
        }
      ],
      steps: [
        {
          title: 'Ajouter le fichier de Canevas dans la plateforme',
          component: UploadForm
        },
        {
          title: 'Mapping des champs',
          component: FieldMapping
        },
        {
          title: 'Soumission du fichier',
          component: SubmitFile
        }
      ],
      columns: [],
      items: [],
      formatedData: [],
      errors: '',
      isDispatchingBlocked: false
    }
  },
  async mounted() {
    this.isDispatchingBlocked = this.user.organization.dispatchingBlocked;
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    setData(this: any, { columns, items }: { columns: any; items: any }) {
      this.columns = columns
      this.items = items
    },

    setFormatedData(this: any, data: any) {
      this.formatedData = data
    },

    nextStep(this: any) {
      this.activeStep++
    },

    prevStep(this: any) {
      this.activeStep--
    },

    onStepClick(step: number) {
      if (step < this.activeStep) {
        this.activeStep = step
      }
    },

    invalidFile(data: string) {
      this.errors = data
    }
  }
})
