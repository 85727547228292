



























































import Vue from 'vue'
import { mapGetters } from 'vuex'
import DispatchingFile from '../../../../../../store/models/DispatchingFile'

export default Vue.extend({
  data() {
    return {
      loading: false,
      showFaillureAlert: false
    }
  },

  computed: {
    ...mapGetters('entities/dispatching_files', ['newDispatchingFile']),

    linesCount(this: any) {
      return this.newDispatchingFile.items.length
    },

    cellsCount(this: any) {
      return this.newDispatchingFile.headers.length
    }
  },

  methods: {
    onSubmit(this: any) {
      this.loading = true

      DispatchingFile.api()
        .create({
          file: this.newDispatchingFile.file,
          creditLineId: this.newDispatchingFile.creditLineId,
          mapping: this.newDispatchingFile.mapping
        })
        .then((response: any) => {
          this.loading = false
          this.$router.push({
            name: 'dispatchings.create.summary',
            query: {
              fileId: response.response.data.id
            }
          })
        })
        .catch((error: any) => {
          this.loading = false
          if (error.response && error.response.status === 422) {
            this.showFaillureAlert = true
          }
        })
    }
  }
})
